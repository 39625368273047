import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'

import mainHeader from '../../../images/solutions/rpa/rpaDiscovery.jpg'
import result from '../../../images/solutions/rpa/rpaDiscoveryResult.png'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { FaqSection } from '../../../components/faq'
import { rpaList } from '../../../utils/customersList'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FaChevronRight } from 'react-icons/fa'
import BreadcrumbComponent from '../../../components/breadcrumb'
import { StaticImage } from 'gatsby-plugin-image'

const faqItems = [
  {
    question: 'É preciso ter um software RPA para a realização do diagnóstico?',
    answer:
      'Não é necessário possuir uma licença de software RPA para a realização do diagnóstico. Este diagnóstico é padronizado e independe do software a ser utilizado.',
  },
  {
    question: 'Em quais áreas de negócio é feito o diagnóstico de automação?',
    answer:
      'O diagnóstico é feito em toda a empresa. Porém, as melhores oportunidades de automação são encontradas em processos que demandam alto volume de execuções e consequentemente quantidade de pessoas, ou que requerem velocidade no atendimento.',
  },
  {
    question: 'Quem participa do diagnóstico de automação?',
    answer:
      'Durante a etapa de análise, devem participar os usuários-chave de cada processo, quem de fato executa as atividades do processo no dia-a-dia e que seja capaz de mostrar a rotina do início ao fim, bem como inputs e outputs do processo. Nas etapas de workshop e apresentação, é fundamental incluir a participação dos gestores das áreas de negócio, além dos usuários-chave.',
  },
  {
    question: 'A quem se destina a realização do diagnóstico de automação?',
    answer:
      'O diagnóstico para automação de processos se destina a empresas que buscam automatizar processos usando a tecnologia RPA. Independente do nível de maturidade processual e tecnológica, o diagnóstico apoia desde empresas que estão iniciando a jornada de automação a empresas que já possuem um squad de automação e um software consolidado.',
  },
]

const DiscoveryPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location

  return (
    <Layout location={location}>
      <SEO title="High Jump - Hi-Discovery" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>Hi-Discovery</h1>
              <p>
                Descubra e explore oportunidades de automação robótica de
                processos (RPA). Acelere projetos de automação através de um
                diagnóstico preciso e aproveite todo o potencial da tecnologia
                em sua operação.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Automação Robótica de Processos"
              replacedLabel="Hi-Discovery"
            />
            <div className={styles.hiRpaSection}>
              <div className={styles.title}>
                <h1>O que é o Hi-Discovery?</h1>
                <p>
                  É um diagnóstico empresarial para identificar oportunidades de
                  automação de processos (RPA) com bom custo benefício, ou seja,
                  viáveis técnica e economicamente.
                </p>
              </div>
              <div className={styles.content}>
                <p>
                  Um <strong>diagnóstico inadequado</strong> de processos pode
                  impactar negativamente no resultado do projeto de RPA, podendo
                  acarretar em:
                </p>
                <ul>
                  <li>Automações de processos ineficientes</li>
                  <li>Baixo retorno sobre o investimento</li>
                  <li>Incompatibilidade com sistemas existentes </li>
                  <li>Resistência interna à mudança </li>
                  <li>Sobrecarga de manutenção de robôs </li>
                  <li>Problemas de compliance e governança </li>
                  <li>Frustração e perda de confiança no projeto.</li>
                </ul>
                <p>
                  Para solucionar estes problemas e alavancar projetos de
                  automação, desenvolvemos uma metodologia própria para realizar
                  ao longo de duas semanas, um diagnóstico detalhado de
                  processos com potencial de automação. Solicite agora e eleve
                  seu projeto de automação para o próximo nível.
                </p>
                <AnchorLink
                  to={`/automacao-robotica-de-processos/discovery#contact`}
                >
                  Solicite mais informações
                  <FaChevronRight />
                </AnchorLink>
              </div>
            </div>

            <div className={styles.hiRpaSection} style={{ marginTop: '3rem' }}>
              <div className={styles.title}>
                <h1>Confira as etapas da metodologia de trabalho</h1>
              </div>

              <div className={styles.processContainer}>
                <p className={styles.processDuration}>
                  Todo o processo leva <strong>10 dias úteis</strong>.
                </p>
                <div className={styles.cardsContainer}>
                  <div className={styles.card}>
                    <span className={styles.label}>1ª etapa - Workshop</span>
                    <p>
                      <strong>Conceitos e aplicações:</strong> tecnologia RPA e
                      mapeamento da cadeia de valor.
                    </p>
                    <p>
                      <strong>Treinamento prático</strong> para as áreas de
                      negócio.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>2ª etapa - Análise</span>
                    <p>
                      <strong>Entrevistas:</strong> mapeamento de processos e
                      análise de viabilidade da automação.
                    </p>
                    <p>
                      <strong>Realizada</strong> junto aos usuários-chave de
                      cada processo.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>
                      3ª etapa - Apresentação
                    </span>
                    <p>
                      <strong>Apresentação:</strong> resultados e lista de
                      priorização de processos.
                    </p>
                    <p>
                      <strong>Reunião</strong> ajustes de entrega com
                      stakeholders.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>Entrega</span>
                    <p>
                      <strong>Roadmap:</strong> custos e prazo de implementação
                      dos robôs.
                    </p>
                    <p>
                      <strong>Estudo</strong> não vinculado a software RPA
                      específico.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.hiRpaSection}
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
            >
              <div className={styles.title}>
                <h1>O que será entregue ao final do processo?</h1>
              </div>
              <div className={styles.contentMid}>
                <div className={styles.text}>
                  <p>
                    Após a conclusão do diagnóstico, será entregue um relatório
                    completo contemplando:
                  </p>
                  <ul>
                    <li>Os recursos necessários para o projeto;</li>
                    <li>Prazo de implementação dos robôs;</li>
                    <li>Volumetria dos processos;</li>
                    <li>Retorno sobre o investimento e payback.</li>
                  </ul>
                  <p>
                    Assim, é possível otimizar os custos do projeto de
                    automação, minimizar riscos e aproveitar todo o potencial da
                    tecnologia.
                  </p>
                </div>
                <div className={styles.image}>
                  <StaticImage
                    src="../../../images/solutions/rpa/rpaDiscoveryResult.png"
                    alt="Conclusao do diagnostico"
                    title="Conclusao do diagnostico"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={rpaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Consultoria para otimização de processos</h2>
                  <p>Evitando que erros ou redundâncias sejam automatizados</p>
                </div>
                <div className={styles.div2}>
                  <h2>Treinamento prático para as áreas de negócio</h2>
                  <p>Com o conteúdo preparatório para implementação do RPA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Estudo de viabilidade de projeto</h2>
                  <p>
                    Contemplando custo, retorno sobre o investimento e payback
                  </p>
                </div>
                <div className={styles.div4}>
                  <h2>Mão de obra especializada e exclusiva</h2>
                  <p>
                    Alocação de profissionais certificados e dedicação exclusiva
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />

          <FaqSection items={faqItems} />

          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default DiscoveryPage
